<template>
  <filter-wrapper
    :class="{ 'mb-4': !isMobile }"
    :has-active-filters="hasActiveFilters"
    @reset="resetAllFilter"
  >
    <v-row dense>
      <v-col cols="12" sm="4" md="3" lg="2">
        <read-status-select v-model="readStatusSelection" />
      </v-col>
      <v-col cols="12" sm="4" md="3" lg="2">
        <sap-number-select v-model="sapNumberSelection" />
      </v-col>
      <v-col cols="12" sm="4" md="3" lg="2">
        <reference-number-select v-model="referenceNumberSelection" />
      </v-col>
      <v-col cols="12" sm="4" md="3" lg="2">
        <creditor-voucher-type-select v-model="typeSelection" />
      </v-col>
      <v-col cols="12" sm="4" md="3" lg="2">
        <date-select v-model="voucherDateSelection" />
      </v-col>
    </v-row>

    <template :slot="isMobile ? 'active-filters' : 'default'">
      <v-row v-if="!isMobile || hasActiveFilters" dense>
        <v-col v-if="hasActiveFilters" cols="12" class="pt-3 pb-4">
          <active-filter-chip
            v-for="(selection, index) in readStatusSelection"
            :key="selection"
            v-model="readStatusSelection"
            color="text"
            :index="index"
            icon="icon-message"
          >
            {{ selection | formatReadStatus }}
          </active-filter-chip>
          <active-filter-chip
            v-for="(selection, index) in sapNumberSelection"
            :key="selection"
            v-model="sapNumberSelection"
            color="text"
            :index="index"
            icon="icon-voucher-number"
          >
            {{ $t('vouchers.customNumber') }}: {{ selection }}
          </active-filter-chip>
          <active-filter-chip
            v-for="(selection, index) in referenceNumberSelection"
            :key="selection"
            v-model="referenceNumberSelection"
            color="text"
            :index="index"
            icon="icon-hash"
          >
            {{ $t('referenceNumber') }}: {{ selection }}
          </active-filter-chip>
          <active-filter-chip
            v-for="(selection, index) in typeSelection"
            :key="selection"
            v-model="typeSelection"
            color="text"
            :index="index"
            icon="icon-vouchers"
          >
            {{ selection | formatCreditorVoucherType }}
          </active-filter-chip>
          <active-filter-chip
            v-for="(selection, index) in voucherDateSelection"
            :key="'voucherDate_' + selection"
            v-model="voucherDateSelection"
            color="text"
            :index="index"
            icon="icon-calendar"
          >
            {{ $t('vouchers.voucherDate') }}:
            <template v-if="!selection.start">
              {{ $t('to') }} {{ selection.end | formatDate }}
            </template>
            <template v-else-if="!selection.end">
              {{ $t('from') }} {{ selection.start | formatDate }}
            </template>
            <template v-else>
              {{ selection.start | formatDate }}
              <template v-if="selection.start !== selection.end">
                - {{ selection.end | formatDate }}
              </template>
            </template>
          </active-filter-chip>

          <app-text-btn
            v-if="hasActiveFilters && !isMobile"
            color="text"
            class="caption"
            title="resetFilter"
            @click="resetAllFilter"
          />
        </v-col>
      </v-row>
    </template>
  </filter-wrapper>
</template>

<script>
import ActiveFilterChip from '@/shared/components/ActiveFilterChip';
import CreditorVoucherTypeSelect from '@/modules/User/components/MasterData/CreditorVouchersFilter/CreditorVoucherTypeSelect';
import DateSelect from '@/shared/DateSelect';
import FilterWrapper from '@/shared/components/FilterWrapper';
import ListFilterMixin from '@/mixins/ListFilterMixin';
import ReadStatusSelect from '@/modules/User/components/MasterData/CreditorVouchersFilter/ReadStatusSelect';
import ReferenceNumberSelect from '@/modules/User/components/MasterData/CreditorVouchersFilter/ReferenceNumberSelect';
import SapNumberSelect from '@/modules/User/components/MasterData/CreditorVouchersFilter/SapNumberSelect';

export default {
  name: 'CreditorVouchersFilter',

  mixins: [ListFilterMixin],

  components: {
    ActiveFilterChip,
    CreditorVoucherTypeSelect,
    DateSelect,
    FilterWrapper,
    ReadStatusSelect,
    ReferenceNumberSelect,
    SapNumberSelect
  }
};
</script>
