<template>
  <v-container fluid>
    <v-row>
      <v-col class="tab__col">
        <h3>{{ $t('masterData.doc2Portal') }}</h3>
        <p>{{ $t('masterData.doc2PortalInfo') }}</p>
        <p class="mt-4 semi-bold">{{ $t('masterData.doc2PortalHelp') }}</p>
      </v-col>
    </v-row>

    <v-row v-if="permissions.masterData.enableDoc2Portal" class="mt-8">
      <v-col :cols="isMobile ? 12 : 6" class="tab__col">
        <h3 class="text-light--text">
          {{ $t('masterData.doc2Portal') }} - {{ $t('masterData.printerDriver') }}
        </h3>
        <p>{{ $t('masterData.printerDriverInfo') }}</p>

        <v-row align="center" class="ma-0 pa-4 mt-8 grey-background">
          <template v-if="creditorDetails.extensions.doc2PortalEnabled">
            <v-col cols="auto">{{ $t('masterData.downloadDriver') }}:</v-col>
            <v-col class="d-flex align-center" cols="auto">
              <app-btn download :href="driverUrl">
                <icon-download class="mr-2 icon--inline" />
                <span>{{ $t('masterData.yourDriver') }}</span>
              </app-btn>
            </v-col>
          </template>
          <v-col v-else>
            {{ $t('masterData.noDriverEnabled') }}
          </v-col>
        </v-row>
      </v-col>
      <extensions-hints-col :sections="$t('masterData.doc2PortalHints')" />
    </v-row>

    <v-row v-if="hasAdminView || permissions.masterData.enableWebApp" class="mt-12">
      <v-col :cols="isMobile ? 12 : 6" class="tab__col">
        <h3 class="text-light--text">
          {{ $t('masterData.doc2Portal') }} - {{ $t('masterData.webAppKey') }}
        </h3>
        <p>{{ $t('masterData.webAppInfo') }}</p>

        <p class="mt-4">{{ $t('masterData.furtherServices') }}:</p>
        <ul class="mt-2">
          <li>{{ $t('masterData.serviceTransferDebtor') }}</li>
          <li>{{ $t('masterData.serviceCheckDebtorLimit') }}</li>
        </ul>

        <v-row align="center" class="ma-0 mt-8 pa-4 grey-background">
          <v-col cols="auto">{{ $t('masterData.yourKey') }}:</v-col>
          <v-col v-if="!webAppKey" cols="auto">
            <app-btn title="masterData.generateKey" :loading="loading" @click="generateWebAppKey" />
          </v-col>
          <v-col v-else-if="!showKey">
            <app-link-btn
              class="ml-n2 body-1"
              color="button-link"
              title="navigation.show"
              variant="muted"
              @click="showKey = true"
            />
          </v-col>
          <v-col v-else class="semi-bold">
            {{ webAppKey }}
          </v-col>
        </v-row>
      </v-col>

      <extensions-hints-col :sections="$t('masterData.webAppHints')" />
    </v-row>

    <v-row v-if="hasAdminView && permissions.masterData.enableLetter2Post" class="mt-12">
      <v-col :cols="isMobile ? 12 : 6" class="tab__col">
        <h3 class="text-light--text">
          {{ $t('masterData.letter2Post') }}
        </h3>

        <v-row align="center" class="ma-0 mt-8 pa-4 grey-background">
          <template v-if="creditorDetails.extensions.letter2PostEnabled">
            <v-col cols="auto">{{ $t('masterData.downloadDriver') }}:</v-col>
            <v-col class="d-flex align-center" cols="auto">
              <app-btn download disabled>
                <icon-download class="mr-2 icon--inline" />
                <span>{{ $t('masterData.yourDriver') }}</span>
              </app-btn>
            </v-col>
          </template>
          <v-col v-else>{{ $t('masterData.noDriverEnabled') }}</v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { BASE_URL } from '@/plugins/axios';
import { mapActions } from 'vuex';
import { NAMESPACE } from '@/modules/User/store';
import ExtensionsHintsCol from '@/modules/User/components/MasterData/ExtensionsHintsCol';

export default {
  name: 'TabExtensions',

  components: { ExtensionsHintsCol },

  props: {
    creditorDetails: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    createdKey: '',
    showKey: false,
    loading: false,
    showInfo: undefined
  }),

  computed: {
    driverUrl() {
      return `${BASE_URL}/doc2portal/driver/${this.$auth.user().creditorId}`;
    },
    webAppKey() {
      return this.creditorDetails.extensions.webAppKey ?? this.createdKey;
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['fetchWebAppKey']),

    async generateWebAppKey() {
      this.loading = true;

      const { key, error } = await this.fetchWebAppKey();

      if (error) {
        this.loading = false;
        return;
      }
      this.createdKey = key;
      this.showKey = true;
      this.loading = false;
    }
  }
};
</script>
