<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h3 :class="{ 'mx-3': isMobile }">
          {{ $t('masterData.creditorVouchers.myCreditorVouchers') }}
        </h3>

        <creditor-vouchers-filter />

        <creditor-vouchers-table
          :table-data="creditorVouchers"
          :count="count"
          :empty="empty"
          :loading-list="loading || !startedInitialFetch"
          @reload="loadCreditorVouchers"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CreditorVouchersFilter from '@/modules/User/components/MasterData/CreditorVouchersFilter/CreditorVouchersFilter';
import CreditorVouchersTable from '@/modules/User/components/MasterData/CreditorVouchersTable';
import ListFilterMixin from '@/mixins/ListFilterMixin';
import { NAMESPACE } from '@/modules/User/store';
import { QueryParam } from '@/statics/queryParam';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TabCreditorVouchers',

  mixins: [ListFilterMixin],

  components: {
    CreditorVouchersTable,
    CreditorVouchersFilter
  },

  data() {
    return {
      creditorVouchers: [],
      empty: false,
      count: 0,
      loading: false,
      startedInitialFetch: false,
      queryValues: [
        QueryParam.PAGE,
        QueryParam.ITEMS_PER_PAGE,
        QueryParam.SORT_BY,
        QueryParam.SORT_DESC,
        'sapNumber',
        'readStatus',
        'referenceNumber',
        'voucherDate',
        'type'
      ],
      defaultSorting: {
        sortBy: ['voucherDate'],
        sortDesc: [true]
      }
    };
  },

  computed: {
    ...mapGetters(NAMESPACE, ['creditorVoucherIdentifiersBySapNumbers'])
  },

  watch: {
    '$route.query': {
      deep: true,
      handler(query) {
        if (!this.startedInitialFetch) {
          return;
        }

        this.updateTableQueries(query, this.queryValues, this.loadCreditorVouchers);
      }
    }
  },

  methods: {
    ...mapActions(NAMESPACE, [
      'fetchCreditorVouchers',
      'fetchCreditorVoucherFilterSapNumbers',
      'fetchCreditorVoucherFilterReferenceNumbers'
    ]),

    async loadCreditorVouchers(query = this.defaultQuery) {
      this.loading = true;

      const { creditorVouchers, empty, count, error } = await this.fetchCreditorVouchers({
        page: query[QueryParam.PAGE],
        rowsPerPage: query[QueryParam.ITEMS_PER_PAGE],
        filters: this.buildFilters(),
        sorting: this.buildSorting(query)
      });

      if (error) {
        this.loading = false;
        return;
      }

      this.creditorVouchers = creditorVouchers;
      this.empty = empty;
      this.count = count;
      this.loading = false;
    },

    buildFilters() {
      let filters = {};
      let identifiers = [];

      if (this.readStatusSelection.length > 0) {
        filters.readStatus = this.readStatusSelection[0];
      }

      if (this.sapNumberSelection.length > 0) {
        identifiers.push(...this.creditorVoucherIdentifiersBySapNumbers(this.sapNumberSelection));
      }

      if (this.referenceNumberSelection.length > 0) {
        filters.sapReferenceNumber = this.referenceNumberSelection;
      }

      if (identifiers.length > 0) {
        filters.identifier = identifiers;
      }

      if (this.voucherDateSelection.length > 0) {
        filters.voucherDate = {
          start: this.voucherDateSelection[0].start / 1000 ?? null,
          end: this.voucherDateSelection[0].end / 1000 ?? null
        };
      }

      if (this.typeSelection.length > 0) {
        filters.type = this.typeSelection.flatMap(
          (type) => this.product.creditorVoucherTypeGroups[type] ?? []
        );
      }

      return Object.keys(filters).length > 0 ? filters : undefined;
    }
  },

  async created() {
    if (this.sapNumberSelection.length > 0 || this.referenceNumberSelection.length > 0) {
      await this.fetchCreditorVoucherFilterSapNumbers();
      await this.fetchCreditorVoucherFilterReferenceNumbers();
    } else {
      this.fetchCreditorVoucherFilterSapNumbers();
      this.fetchCreditorVoucherFilterReferenceNumbers();
    }

    this.loadCreditorVouchers();

    this.startedInitialFetch = true;
  }
};
</script>
