<template>
  <div>
    <v-menu :close-on-content-click="false" close-delay="500" offset-y open-on-hover top>
      <template #activator="{ on }">
        <app-icon-btn icon="mdi-dots-horizontal-circle-outline" v-on="on" />
      </template>
      <v-card class="pa-4">
        <span class="subtitle">{{ $t('actions') }}</span>
        <app-text-btn
          append-icon="icon-message"
          class="subtitle-1 d-block mt-2"
          icon-size="17px"
          title="readAll"
          @click="onReadAll"
        />
        <v-divider class="my-4" />
        <span class="subtitle">{{ $t('selectAndDownload') }}</span>
        <div class="semi-bold mb-4">
          {{ $tc('selectedItemsToDownloadCounted', selectedItems.length) }}
        </div>
        <v-row align="center" no-gutters>
          <v-col cols="auto">
            <v-switch
              v-model="selectionIsShown"
              inset
              hide-details
              :label="$t('showSelect')"
              class="select-menu__switch subtitle-1"
            />
          </v-col>
          <v-col cols="auto">
            <app-text-btn
              :disabled="noFilesSelected"
              :loading="loading"
              append-icon="icon-download"
              class="subtitle-1 btn--nowrap"
              icon-size="17"
              title="download"
              @click="onDownload"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-menu>
    <v-bottom-sheet
      v-if="isMobile"
      ref="sheet"
      :retain-focus="false"
      :value="!noFilesSelected"
      hide-overlay
      no-click-animation
      persistent
    >
      <v-sheet class="text-center secondary text--white px-4">
        <p class="white--text semi-bold pt-4">
          {{ $tc('selectedItemsToDownloadCounted', selectedItems.length) }}
        </p>
        <v-row>
          <v-col>
            <app-btn block color="white" outlined title="navigation.cancel" @click="onCancel" />
          </v-col>
          <v-col>
            <app-btn :loading="loading" block color="white" title="download" @click="onDownload" />
          </v-col>
        </v-row>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
export default {
  name: 'TableActionsMenu',

  props: {
    noFilesSelected: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    selectedItems: {
      type: Array,
      required: true
    },
    showSelection: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    selectionIsShown: {
      get() {
        return this.showSelection;
      },
      set(value) {
        this.$emit('update:showSelection', value);
      }
    }
  },

  watch: {
    noFilesSelected(value) {
      if (value || !this.$refs.sheet) {
        return;
      }

      //enable scrolling while bottom sheet is shown
      this.$nextTick(() => {
        this.$refs.sheet.showScroll();
      });
    }
  },

  methods: {
    onCancel() {
      this.$emit('update:selected-items', []);
    },

    onDownload() {
      this.$emit('download');
    },

    onReadAll() {
      this.$emit('read-all');
    }
  }
};
</script>

<style scoped lang="scss">
.subtitle {
  display: block;
  margin-bottom: 16px;
}

.select-menu__switch ::v-deep .v-input__slot {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.select-menu__switch {
  margin: 0 0.5rem 0 0;
  padding-top: 2px;
}

.select-menu__switch ::v-deep .v-input__slot label {
  flex: 0 0 auto;
  margin-right: 1rem;
  font-size: inherit;
  color: inherit;
}

.v-btn.btn--nowrap,
.v-application.mobile .v-btn.btn--nowrap {
  white-space: nowrap;
}
</style>
