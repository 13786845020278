<template>
  <app-tab-view-container
    :error="creditorDetailsError"
    :loading="loading"
    :tab-props="{ creditorDetails }"
    :tabs="tabs"
  >
    <template #append>
      <v-row v-if="hasAdminView" class="mx-4 mt-12 flex-auto">
        <v-col>
          <app-api-log :data="creditorDetailsRaw" api-name="creditor/show" />
        </v-col>
      </v-row>
    </template>
  </app-tab-view-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { NAMESPACE } from '@/modules/User/store';
import AppTabViewContainer from '@/shared/components/AppTabViewContainer';
import TabContract from '@/modules/User/components/MasterData/TabContract';
import TabCreditorVouchers from '@/modules/User/components/MasterData/TabCreditorVouchers';
import TabAbout from '@/modules/User/components/MasterData/TabAbout';
import TabExtensions from '@/modules/User/components/MasterData/TabExtensions';

export default {
  name: 'MasterData',

  components: {
    AppTabViewContainer
  },

  data: () => ({
    loading: false
  }),

  computed: {
    ...mapState(NAMESPACE, ['creditorDetails', 'creditorDetailsRaw', 'creditorDetailsError']),
    tabs() {
      return [
        {
          id: 1,
          name: this.$t('masterData.myContract'),
          component: TabContract,
          condition: this.permissions.masterData.tabMyContract
        },
        {
          id: 2,
          name: this.$t('masterData.creditorVoucherPl'),
          component: TabCreditorVouchers,
          condition: this.permissions.masterData.tabCreditorVouchers
        },
        {
          id: 3,
          name: this.$t('masterData.aboutMe'),
          component: TabAbout,
          condition: this.permissions.masterData.tabAboutMe
        },
        {
          id: 4,
          name: this.$t('masterData.extensions'),
          component: TabExtensions,
          condition: this.permissions.masterData.tabExtensions
        }
      ];
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['fetchCreditorDetails'])
  },

  async created() {
    this.loading = true;
    await this.fetchCreditorDetails();
    this.loading = false;
  }
};
</script>
