<template>
  <app-data-table
    :headers="tableHeaders"
    :items="formattedTableData"
    :loading="loadingList"
    :no-data-text="$t(empty ? 'masterData.creditorVouchers.noData' : 'noMatchingResults')"
    :selectable="showSelection"
    :selected-items.sync="selectedItems"
    :total-data-length="count"
  >
    <template #title>
      <div class="table-title__content">
        <div :class="classesTitleLeft">
          <div class="d-flex align-center">
            {{ $tc('masterData.creditorVouchers.creditorVouchersCounted', count) }}
            <table-actions-menu
              :no-files-selected="noItemsSelected"
              :loading="downloadingFiles"
              :selected-items.sync="selectedItems"
              :show-selection.sync="showSelection"
              class="ml-2"
              @download="onDownload"
              @read-all="onReadAll"
            />
          </div>
        </div>
      </div>
    </template>
    <template #item="{ item, selectable }">
      <app-table-row :selectable="selectable" :selected-items.sync="selectedItems" :item="item">
        <app-table-data :sorted="isSorted(0)" :header="tableHeaders[0]">
          <read-status-button
            :loading="isLoading(item)"
            :is-unread="isUnread(item)"
            @click="toggleReadStatus(item)"
          />
        </app-table-data>
        <app-table-data :sorted="isSorted(1)" :header="tableHeaders[1]">
          {{ item.sapNumber }}
        </app-table-data>
        <app-table-data :sorted="isSorted(2)" :header="tableHeaders[2]">
          {{ item.referenceNumber }}
        </app-table-data>
        <app-table-data :sorted="isSorted(3)" :header="tableHeaders[3]">
          {{ item.type }}
        </app-table-data>
        <app-table-data :sorted="isSorted(4)" :header="tableHeaders[4]">
          {{ item.voucherDate | formatDate }}
        </app-table-data>
        <app-table-data :sorted="isSorted(5)" :header="tableHeaders[5]">
          {{ item.amount | formatCurrency }}
        </app-table-data>
        <app-table-data :header="tableHeaders[5]">
          <file-link-btn
            :disabled="!item.source"
            :loading="!!item.sapNumber && loadingFileType === item.sapNumber"
            :title="$t(!item.source ? 'masterData.inProgress' : 'pdf')"
            append-icon
            @click="openFile(item.source, item.sapNumber, $t('pdf'))"
          />
        </app-table-data>
      </app-table-row>
    </template>
  </app-data-table>
</template>

<script>
import FileLinkBtn from '@/shared/components/FileLinkBtn';
import OpenFileMixin from '@/mixins/OpenFileMixin';
import ReadStatus from '@/modules/User/statics/readStatus';
import ReadStatusButton from '@/modules/User/components/ReadStatusButton';
import TableActionsMenu from '@/shared/components/TableActionsMenu';
import TableMixin from '@/mixins/TableMixin';
import formatCreditorVoucherType from '@/helper/filter/formatCreditorVoucherType';
import formatDate from '@/helper/filter/formatDate';
import { NAMESPACE } from '@/modules/User/store';
import { mapActions } from 'vuex';

export default {
  name: 'CreditorVouchersTable',

  components: { FileLinkBtn, ReadStatusButton, TableActionsMenu },

  mixins: [OpenFileMixin, TableMixin],

  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    count: {
      type: Number,
      default: 0
    },
    empty: {
      type: Boolean,
      default: false
    },
    loadingList: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    loading: false,
    loadingReadStatusIds: []
  }),

  computed: {
    tableHeaders() {
      return [
        {
          text: this.isMobile ? this.$t('masterData.creditorVouchers.readStatus') : '',
          value: 'readStatus',
          sortable: false,
          width: '40px'
        },
        {
          text: this.isMobile ? this.$t('vouchers.customNo') : this.$t('vouchers.customNumber'),
          value: 'sapNumber',
          sortMobile: true,
          alignLeft: true
        },
        { text: this.$t('referenceNumber'), value: 'referenceNumber' },
        { text: this.$t('type'), value: 'type', sortable: false },
        { text: this.$t('date'), value: 'voucherDate', sortMobile: true },
        { text: this.$t('amount'), value: 'amount', sortMobile: true },
        { text: this.$t('document'), sortable: false, value: 'source' }
      ];
    },
    formattedTableData() {
      // return already formatted table data content for correct sorting
      return JSON.parse(JSON.stringify(this.tableData)).map((creditorVoucher) => ({
        ...creditorVoucher,
        type: formatCreditorVoucherType(creditorVoucher.type)
      }));
    }
  },

  methods: {
    ...mapActions(NAMESPACE, [
      'fetchCreditorVoucherFiles',
      'readAllCreditorVouchers',
      'readCreditorVouchers',
      'unreadCreditorVouchers'
    ]),

    isLoading(entry) {
      return this.loadingReadStatusIds.includes(entry.id);
    },

    isUnread(entry) {
      return entry.readStatus !== ReadStatus.READ;
    },

    async toggleReadStatus(entry) {
      this.loadingReadStatusIds.push(entry.id);

      if (this.isUnread(entry)) {
        await this.readCreditorVouchers([entry.id]);
      } else {
        await this.unreadCreditorVouchers([entry.id]);
      }

      this.$emit('reload');
      this.loadingReadStatusIds = this.loadingReadStatusIds.filter((el) => el !== entry.id);
    },

    async onReadAll() {
      this.loadingReadStatusIds = this.formattedTableData
        .filter((el) => this.isUnread(el))
        .map((el) => el.id);

      await this.readAllCreditorVouchers();

      this.loadingReadStatusIds = [];
      this.$emit('reload');
    },

    async onDownload() {
      this.downloadingFiles = true;
      const { file, error } = await this.fetchCreditorVoucherFiles(this.selectedItems);

      if (error) {
        this.downloadingFiles = false;
        return;
      }

      this.triggerDownload(
        file,
        this.$t('masterData.creditorVouchers.downloadFilename', { date: formatDate(new Date()) }),
        true
      );

      this.downloadingFiles = false;
    }
  }
};
</script>

<style scoped>
#app.v-application .theme--light.v-data-table.app-data-table tr >>> td {
  padding: 0.5rem 1rem;
  height: unset;
}
</style>
