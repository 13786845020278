<template>
  <v-col :cols="isMobile ? 12 : 6" class="hints__container" align-self="center">
    <div class="pa-4 hints__box">
      <span class="text-h5 text-light--text">
        {{ $t('hints') }}
      </span>

      <template v-for="section in sections">
        <div :key="section.title" class="semi-bold mt-6 mb-2 subtitle-1">
          {{ section.title }}
        </div>
        <div :key="section.text" class="caption" v-html="section.text"></div>
      </template>
    </div>
  </v-col>
</template>

<script>
export default {
  name: 'ExtensionsHintsCol',

  props: {
    sections: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>
#app.v-application:not(.mobile) .hints__container {
  padding-left: 5rem;
}

.hints__box {
  border: 2px solid var(--c-grey-light);
}
</style>
