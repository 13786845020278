<template>
  <app-autocomplete
    filter
    :items="options"
    :label="$t('referenceNumber')"
    label-icon="icon-hash"
    hide-details
    multiple
    dense
    solo
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { mapState } from 'vuex';
import { NAMESPACE } from '@/modules/User/store';

export default {
  name: 'ReferenceNumberSelect',

  computed: {
    ...mapState(NAMESPACE, { items: 'creditorVoucherReferenceNumbers' }),

    options() {
      return [...new Set(this.items.map(({ sapReferenceNumber }) => sapReferenceNumber))];
    }
  }
};
</script>
